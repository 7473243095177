import { useState } from "react";
import Confetti from "react-confetti";

const Frame = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5", // Light background to enhance visibility
      }}
    >
      {/* Full-screen iframe */}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          // border: "3px solid #ccc", 
          borderRadius: "0px", // No rounded corners for full-screen effect
          overflow: "hidden",
          boxShadow: "none", // Remove shadow for a seamless look
        }}
      >
        <iframe
          src="https://predictify.app"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          title="Predictify"
        ></iframe>
      </div>
    </div>
  );
};

export default Frame;
