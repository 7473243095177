import React, { useState, useEffect } from 'react';
import Image1 from './AD-1.png';
import Image2 from './AD-2.png';
import Image3 from './AD-3.png';
import Image4 from './AD-4.png';

function AdComponent() {
  const [isVisible, setIsVisible] = useState(true);
  const [currentImage, setCurrentImage] = useState(null);
  

  const adBanners = [
    { src: Image1, alt: "Ad Banner 1", link: "https://www.betstorm.com/?nav=registration&tracker=233000&btag=6aeaf72ec11b45bdb1d29f3892f7e5ba&affid=3640&lang=en" },
    { src: Image2, alt: "Ad Banner 2", link: "https://www.betstorm.com/?nav=registration&tracker=233000&btag=6aeaf72ec11b45bdb1d29f3892f7e5ba&affid=3640&lang=en" },
    { src: Image3, alt: "Ad Banner 3", link: "https://www.luckbuckbets.com/?tracker=&btag=ce649ee7fdbf424b87f5ec513472a2dc&affid=12541&lang=en&cid=17889&campname=Home+Page" },
    { src: Image4, alt: "Ad Banner 4", link: "https://www.luckbuckbets.com/?tracker=&btag=ce649ee7fdbf424b87f5ec513472a2dc&affid=12541&lang=en&cid=17889&campname=Home+Page" }
  ];
  
  // Function to get a random banner
  const getRandomBanner = () => {
    const randomIndex = Math.floor(Math.random() * adBanners.length);
    return adBanners[randomIndex];
  };
  
  // Set a random banner when the component mounts
  useEffect(() => {
    setCurrentImage(getRandomBanner());
  }, []); // Empty dependency array means this runs once on mount
  
  const handleClose = (e) => {
    e.preventDefault(); // Prevent clicking through to the ad link
    e.stopPropagation(); // Stop event bubbling
    setIsVisible(false);
  };
  
  if (!isVisible || !currentImage) {
    return null;
  }
  
  return (
    <div className="d-flex justify-content-center my-3">
      <div className="position-relative" style={{ maxWidth: '900px', width: '100%' }}>
        <a 
          href={currentImage.link}
          target="_blank" 
          rel="noopener noreferrer"
          className="d-block"
        >
          <img
            src={currentImage.src}
            alt={currentImage.alt}
            className="img-fluid"
            style={{
              width: '100%',
              aspectRatio: '4 / 1',
              height: 'auto',
              cursor: 'pointer',
              borderRadius: '3px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          />
        </a>
        
        <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10, // Ensure button is above the image
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}

export default AdComponent;
