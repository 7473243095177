import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

function test() {
  return (
    <div>
      <Navbar />
      <div></div>
      <Footer />
    </div>
  )
}

export default test