import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { MdSportsSoccer } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './HomePageTest.css';
import Flag from 'react-world-flags';
import { ref, getDatabase, onValue } from 'firebase/database';
import AdComponent from './AdComponent';

function HomePageTest() {
  const { t } = useTranslation();
  const [authUser, setAuthUser] = useState(null);
  const [brackets, setBrackets] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const cards = [
    {
      title: 'Premier League',
      description: t('epl'),
      linkState: 'English Premier League',
      learnMore: '/epllearnmore',
      flagCode: 'GB_ENG',
    },
    {
      title: 'Scottish Premiership',
      description: t('scottish_premiership_description'),
      linkState: 'Scottish Premiership',
      learnMore: '/spfllearnmore',
      flagCode: 'GB_SCT',
    },
    {
      title: 'Championship',
      description: t('english_championship_description'),
      linkState: 'Championship',
      learnMore: '/championshiplearnmore',
      flagCode: 'gb',
    },
    {
      title: 'La Liga',
      description: t('la_liga_description'),
      linkState: 'La Liga',
      learnMore: '/laligalearnmore',
      flagCode: 'es',
    },
    {
      title: 'Champions League',
      description: t('champions_league_description'),
      linkState: 'UCL',
      learnMore: '/ucllearnmore',
      flagCode: 'EU',
    },
    {
      title: 'Bundesliga',
      description: t('bundesliga_description'),
      linkState: 'Bundesliga',
      learnMore: '/bundesligalearnmore',
      flagCode: 'de',
    },
    {
      title: 'Serie A',
      description: t('serie_a_description'),
      linkState: 'Serie A',
      learnMore: '/seriealearnmore',
      flagCode: 'it',
    },
    {
      title: 'Premier Soccer League',
      description: t('premier_soccer_league_description'),
      linkState: 'SASoccer',
      learnMore: '/sasoccerlearnmore',
      flagCode: 'za',
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        setUserEmail(user.email);
      } else {
        setAuthUser(null);
        setUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBrackets = async () => {
      try {
        const database = getDatabase();
        const bracketsRef = ref(database, 'CreateArticle');
        onValue(bracketsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const bracketsArray = Object.entries(data);
            bracketsArray.sort((a, b) => new Date(b[1].timestamp) - new Date(a[1].timestamp));
            setBrackets(bracketsArray.slice(0, 3));
          }
        });
      } catch (error) {
        console.error('Error fetching brackets:', error);
      }
    };

    fetchBrackets();
  }, []);

  return (
    <div>
      <div className='App-headerV8'>
        <Navbar />
        <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <div>
  <script type="text/javascript" 
          src="//cdn.tsyndicate.com/sdk/v1/bi.js" 
          data-ts-spot="c72b65b387304867b13f0012faf13d38" 
          data-ts-width="900" 
          data-ts-height="250" 
          data-ts-extid="{64046}" 
          async 
          defer>
  </script>
</div>

              <h2 className="mb-4 display-5 text-center text-light  wave-text" style={{ fontFamily: 'Lato, sans-serif' }}>Predictify</h2>
              <p className="text-light mb-2 text-center lead fs-4 text-light " style={{ fontFamily: 'Lato, sans-serif', fontWeight: '300' }}>{t('welcome_message')}</p>
                {authUser ? (
                  <div>
                    {authUser.emailVerified ? null : null}
                  </div>
                ) : (
                  <p className="text-light mb-5 text-center lead fs-4 d-flex justify-content-center">
                    <span className="text-secondary mb-2 lead fs-4 homehover" style={{ marginRight: '5px' }}>
                      <Link className='homehover' to='/signup' style={{ textDecoration: 'none', color: '#00FF00' }}> {t('Sign Up')}
                      </Link>
                    </span>
                    {t('now_to_join_in_the_fun')}

                  </p>
                )}
                <hr className="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>

          <div className="container overflow-hidden" style={{ marginTop: '50px' }}>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              navigation={true}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }}
              speed={800}
              breakpoints={{
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 }
              }}
              className="mySwiper"
            >
              {cards.map((card, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="text-center card-slide border border-white responsive-card"
                    style={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                      position: 'relative',
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: '1px solid white',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      margin: '0.5rem',
                      justifyContent: 'space-between',
                      transition: 'transform 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => e.currentTarget.classList.add('hovered')}
                    onMouseLeave={(e) => e.currentTarget.classList.remove('hovered')}
                  >
                    <div className="px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                      <MdSportsSoccer size={58} style={{ color: 'white' }} className="mb-4" />
                      <h4 className="text-white">{card.title}</h4>
                      <p>{card.description}</p>
                    </div>

                    <div className="mb-3 mt-auto">
                      <Link to="/joinhuddle" state={{ tournament: card.linkState }}>
                        <span className="btn btn-outline-danger text-light snake-border-btn">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <b>{t('playnow')}</b>
                        </span>
                      </Link>
                      <br />
                      <Link to={card.learnMore}>
                        <span className="btn text-white" style={{ paddingBottom: '5px' }}>
                          {t('learnmore')}
                        </span>
                      </Link>
                    </div>

                    <Link
                      to={card.learnMore}
                      className="btn btn-transparent border-0"
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '70px',
                        height: '70px',
                        color: 'white',
                        textDecoration: 'none',
                        borderColor: 'white',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        borderRadius: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Flag code={card.flagCode} height="24" />
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>

        {/* News start */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8">
  <div className="container">
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 className="display-5 mb-4 mb-md-5 text-center text-light">{t('latest_news')}</h2>
        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>
  <div className="container overflow-hidden">
    <div className="row gy-4">
      {brackets.map(([bracketId, bracket], index) => (
        <div key={index} className="col-12 col-md-4">
          <article>
            <div className="card border-0 bg-transparent">
            <figure className="card-img-top mb-4 overflow-hidden bsb-overlay-hover">
 <Link to={`/article/${bracketId}`}> 
   <div style={{
     width: '100%',
     height: '250px',
     overflow: 'hidden', 
     position: 'relative'
   }}>
     <img 
       className="img-fluid bsb-scale bsb-hover-scale-up"
       loading="lazy"
       src={bracket.image}
       alt="new"
       style={{
         width: '100%',
         height: '100%',
         objectFit: 'cover',
         objectPosition: 'center'
       }}
     />
   </div>
 </Link>
 <figcaption style={{
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   textAlign: 'center',
   zIndex: 2
 }}>
   <svg 
     xmlns="http://www.w3.org/2000/svg" 
     width="32" 
     height="32" 
     fill="currentColor" 
     className="bi bi-eye text-white bsb-hover-fadeInLeft" 
     viewBox="0 0 16 16"
   >
     <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
     <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
   </svg>
   <h4 className="h6 text-white bsb-hover-fadeInRight mt-2 text-light">{t('read_more')}</h4>
 </figcaption>
</figure>
              <div className="card-body m-0 p-0">
                <div className="entry-header mb-3">
                  <ul className="entry-meta list-unstyled d-flex mb-3">
                    <li>
                      <a className="d-inline-flex px-2 py-1 link-primary text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-2 text-decoration-none fs-7" href="#!">New</a>
                    </li>
                  </ul>
                  <h2 className="card-title entry-title h4 m-0">
                    <Link className="link-light text-decoration-none text-light" to={`/article/${bracketId}`}>{bracket.title}</Link>
                  </h2>
                </div>
              </div>
              <div className="card-footer border-0 bg-transparent p-0 m-0">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <span className="fs-7 text-light d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span className="ms-2">{bracket.date}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      ))}
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col text-center">
        <Link to={'/news'} className="btn bsb-btn-2xl btn-light rounded-pill mt-5">{t('all_articles')}</Link>
      </div>
    </div>
  </div>
</section>
        {/* News start */}
<div className='container'>
<div className="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
  <AdComponent />
</div>
</div>
 

        <Footer />
      </div>
    </div>
  );
}

export default HomePageTest;