
import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'
import StMirren from '../Team-Kits-Images/StMirren.png'
import { useTranslation } from 'react-i18next';


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import ScottishStandingsPicks from './ScottishStandingsPicks'
import AdComponent from '../AdComponent'
 
function ScottishPicks24() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); // Initialize to the current round, e.g., round 8
  const [buttonsToShow, setButtonsToShow] = useState(0);
  const dropdownMenuRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const fetchTotalPoints = async (displayName) => { 
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
      console.log("Fetched total points:", totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `Scottish2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
      console.log("Fetched picks:", userPicks);
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-07-03', end: '2024-08-06' },  // Matchweek 1
    { start: '2024-08-06', end: '2024-08-12' },  // Matchweek 2
    { start: '2024-08-12', end: '2024-08-26' },  // Matchweek 3
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4 // 
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
    { start: '2024-09-16', end: '2024-09-30' },  // Matchweek 6
    { start: '2024-09-30', end: '2024-10-07' },  // Matchweek 7
    { start: '2024-10-07', end: '2024-10-21' },  // Matchweek 8
    { start: '2024-10-21', end: '2024-10-28' },  // Matchweek 9
    { start: '2024-10-28', end: '2024-10-31' },  // Matchweek 10
    { start: '2024-10-31', end: '2024-11-04' },  // Matchweek 11
    { start: '2024-11-04', end: '2024-11-11' },  // Matchweek 12 // 
    { start: '2024-11-11', end: '2024-11-24' },  // Matchweek 13
    { start: '2024-11-24', end: '2024-12-02' },  // Matchweek 14
    { start: '2024-12-02', end: '2024-12-05' },  // Matchweek 15
    { start: '2024-12-05', end: '2024-12-09' },  // Matchweek 16
    { start: '2024-12-09', end: '2024-12-16' },  // Matchweek 17
    { start: '2024-12-16', end: '2024-12-23' },  // Matchweek 18
    { start: '2024-12-23', end: '2024-12-27' },  // Matchweek 19
    { start: '2024-12-27', end: '2024-12-30' },  // Matchweek 20 // end of 2024
    { start: '2024-12-30', end: '2025-01-03' },  // Matchweek 21
    { start: '2025-01-03', end: '2025-01-10' },  // Matchweek 22
    { start: '2025-01-10', end: '2025-01-16' },  // Matchweek 23
    { start: '2025-01-16', end: '2025-01-26' },  // Matchweek 24
    { start: '2025-01-26', end: '2025-02-03' },  // Matchweek 25
    { start: '2025-02-03', end: '2025-02-16' },  // Matchweek 26
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 27
    { start: '2025-02-23', end: '2025-02-27' },  // Matchweek 28
    { start: '2025-02-27', end: '2025-03-02' },  // Matchweek 29
    { start: '2025-03-02', end: '2025-03-17' },  // Matchweek 30
    { start: '2025-03-17', end: '2025-03-30' },  // Matchweek 31
    { start: '2025-03-30', end: '2025-04-06' },  // Matchweek 32
    { start: '2025-04-06', end: '2025-04-23' },  // Matchweek 33
    // Add more matchweeks as needed
]);


useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  

  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
        console.log("Fetched fixtures:", fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };
  
    fetchFixtures();
  }, []);

  const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
      }
    }));
  };

// Function to award points for a specific user's fixture
const awardPointsForUser = async (userDisplayName, fixtureId) => {
  const fixture = fixtures.find(f => f.fixture.id === parseInt(fixtureId));
  const userPrediction = inputsData[fixtureId];

  if (fixture && userPrediction && (fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET') && !userPrediction.pointsAwarded) {
    const actualHomeScore = fixture.goals.home;
    const actualAwayScore = fixture.goals.away;
    const predictedHomeScore = parseInt(userPrediction.home, 10);
    const predictedAwayScore = parseInt(userPrediction.away, 10);

    console.log(`Checking fixture ${fixtureId}: actual (${actualHomeScore}, ${actualAwayScore}), predicted (${predictedHomeScore}, ${predictedAwayScore})`);

    let pointsToAward = 0;

    // Exact match
    if (predictedHomeScore === actualHomeScore && predictedAwayScore === actualAwayScore) {
      pointsToAward = 3;
    } else {
      // Correct outcome
      const actualOutcome = actualHomeScore > actualAwayScore ? 'home' : actualHomeScore < actualAwayScore ? 'away' : 'draw';
      const predictedOutcome = predictedHomeScore > predictedAwayScore ? 'home' : predictedHomeScore < predictedAwayScore ? 'away' : 'draw';

      if (actualOutcome === predictedOutcome) {
        pointsToAward = 1;
      }
    }

    if (pointsToAward > 0) {
      await updatePoints(userDisplayName, fixtureId, pointsToAward);
    }
  }
};

// Function to update points for a specific user
const updatePoints = async (displayName, fixtureId, points) => {
  const db = getDatabase();
  const userRef = ref(db, `Scottish2024Picks/${displayName}/${fixtureId}`);
  const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

  try {
    const currentFixtureData = (await get(userRef)).val() || {};
    if (!currentFixtureData.pointsAwarded) {
      await set(userRef, {
        ...currentFixtureData,
        points: (currentFixtureData.points || 0) + points,
        pointsAwarded: true
      });

      const totalPointsSnapshot = await get(totalPointsRef);
      const currentTotalPoints = totalPointsSnapshot.val() || { totalPoints: 0 };

      const newTotalPoints = currentTotalPoints.totalPoints + points;

      await set(totalPointsRef, { totalPoints: newTotalPoints });

      setTotalPoints(newTotalPoints);
      console.log(`Updated points for ${displayName}:`, { fixtureId, points: (currentFixtureData.points || 0) + points });
    }
  } catch (error) {
    console.error('Error updating points:', error);
  }
};

// Fetch picks and award points for all users
const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const picksRef = ref(db, `Scottish2024Picks`);

    const picksSnapshot = await get(picksRef);
    const picksData = picksSnapshot.val() || {};

    for (const userDisplayName in picksData) {
      if (picksData.hasOwnProperty(userDisplayName)) {
        const userPicks = picksData[userDisplayName];
        if (userPicks[fixtureId]) {
          await awardPointsForUser(userDisplayName, fixtureId);
        }
      }
    }
  } catch (error) {
    console.error('Error awarding points for all users:', error);
  }
};

useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const startPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
  }

  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000);
};


const handleSubmit = async () => {
  if (userId) {
    const db = getDatabase();
    const picksRef = ref(db, `Scottish2024Picks/${displayName}`);
    const totalPointsRef = ref(db, `Scottish2024TotalPoints/${displayName}`);

    let userDisplayName = user.displayName || 'Anonymous';

    // Extract user ID within parentheses
    const match = userDisplayName.match(/\(([^)]+)\)/);
    if (match && match[1]) {
      userDisplayName = match[1];
    }

    try {
      // Fetch existing picks from the backend
      const picksSnapshot = await get(picksRef);
      const existingPicksData = picksSnapshot.val() || {};

      // Debug: Log the current inputsData before filtering
      console.log("inputsData before filtering:", inputsData);

      // Filter inputsData to only include fixtures where a valid prediction is made
      const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
        const fixturePrediction = inputsData[fixtureId];

        // Check if home or away prediction is made (0 is valid, undefined is not)
        if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
          acc[fixtureId] = {
            home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
            away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
          };
        }
        return acc;
      }, {});

      // Debug: Log the filtered inputsData after filtering
      console.log("filteredInputsData:", filteredInputsData);

      // Check if any predictions are available for submission
      if (Object.keys(filteredInputsData).length === 0) {
        console.log("No predictions made, nothing to submit.");
        return;  // Exit early if there are no valid predictions
      }

      // Merge new filtered picks with existing picks
      const mergedPicks = {
        ...existingPicksData,
        ...filteredInputsData
      };

      // Debug: Log the merged data being sent to the backend
      console.log("Merged picks data being submitted:", mergedPicks);

      // Submit the merged picks to the backend
      await set(picksRef, mergedPicks);

      // Check if totalPoints entry exists, if not, initialize it
      const totalPointsSnapshot = await get(totalPointsRef);
      if (!totalPointsSnapshot.exists()) {
        await set(totalPointsRef, { totalPoints: 0 });
      }

      setSent(true);
      setTimeout(() => setSent(false), 15000);
      setConfetti(true);
      setTimeout(() => setConfetti(false), 5000);

      // Start points incrementing for all fixtures with a prediction
      Object.keys(filteredInputsData).forEach(fixtureId => {
        startPointsIncrement(fixtureId);
      });
    } catch (error) {
      console.error('Error submitting picks:', error);
      setError(true);
      setTimeout(() => setError(false), 5000);
    }
  }
};

  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  
  const totalPointsa = useMemo(() => {
    return Object.values(inputsData).reduce((acc, input) => {
      return acc + (input.points || 0);
    }, 0);
  }, [inputsData]);

  const teamInitials = {
    'Celtic': 'CEL',
    'Heart Of Midlothian': 'HEA',
    'Motherwell': 'MOT',
    'Dundee Utd': 'DUU',
    'ST Mirren': 'STM',
    'Kilmarnock': 'KIL',
    'Hibernian': 'HIB',
    'Dundee': 'DUN',
    'Ross County': 'ROS',
    'Rangers': 'RAN',
    'Aberdeen': 'ABE',
    'ST Johnstone': 'STJ',
  };
  

  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const teamLogos = {
    'Aberdeen': Aberdeen,
    'Celtic': Celtic,
    'Heart Of Midlothian': Hearts,
    'Motherwell': Motherwell,
    'Dundee': Dundee,
    'Kilmarnock': Kilmarnock,
    'Rangers': Rangers,
    'Hibernian': Hibernian,
    'ST Johnstone': StJohnstone,
    'Ross County': RossCounty,
    'ST Mirren': StMirren,
    'Dundee Utd': DundeeUtd,
};

const renderTeamLogo = (teamName) => {
const logo = teamLogos[teamName];
if (logo) {
  return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
}
return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
};
const stadiums = {
  'Aberdeen': `${t('stadium')}: Pittodrie Stadium`,
  'Celtic': `${t('stadium')}: Celtic Park`,
  'Heart Of Midlothian': `${t('stadium')}: Tynecastle Park`,
  'Motherwell': `${t('stadium')}: Fir Park`,
  'Dundee': `${t('stadium')}: Dens Park`,
  'Kilmarnock': `${t('stadium')}: Rugby Park`,
  'Rangers': `${t('stadium')}: Ibrox Stadium`,
  'Hibernian': `${t('stadium')}: Easter Road`,
  'ST Johnstone': `${t('stadium')}: McDiarmid Park`,
  'Ross County': `${t('stadium')}: Global Energy Stadium`,
  'ST Mirren': `${t('stadium')}: St Mirren Park`,
  'Dundee Utd': `${t('stadium')}: Tannadice Park`,
};
const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

const roundsContainerRef = useRef(null);


const handleMatchdaySelect = (index) => {
  const Round = `Round ${index + 1}`;
  setSelectedMatchday(`Round ${index + 1}`);
  handlePageChange(index + 1);
};

// Handle Previous Button Click
// Handle Direct Matchday Selection
const handleMatchdaySelectNew = (index) => {
  setSelectedMatchdayIndex(index);
};
useEffect(() => {
  const calculateButtonsToShow = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
      setButtonsToShow(Math.min(8, calculatedButtonsToShow));
      calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
    }
  };

  calculateButtonsToShow();

  // Recalculate on window resize
  window.addEventListener('resize', calculateButtonsToShow);
  return () => {
    window.removeEventListener('resize', calculateButtonsToShow);
  };
}, [currentPage]);

const calculateStartIndex = (currentPage, buttonsToShow) => {
  let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
  minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
  setStartIndex(minIndex);
};

const handleMatchdaySelectSmooth = (index) => {
  setSelectedMatchdayIndex(index);
  handleMatchdaySelect(index);
};

const handleLeftArrowClick = () => {
  if (roundsContainerRef.current && startIndex > 0) {
    roundsContainerRef.current.scrollBy({
      left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
      behavior: 'smooth',
    });
    setStartIndex(startIndex - 1);
  }
};

const handleRightArrowClick = () => {
  if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
    roundsContainerRef.current.scrollBy({
      left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
      behavior: 'smooth',
    });
    setStartIndex(startIndex + 1);
  }
};
  
useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

// Add new effect to handle dropdown button click
useEffect(() => {
  const handleDropdownOpen = () => {
    if (dropdownMenuRef.current) {
      // Wait for the dropdown to be visible
      setTimeout(() => {
        const currentRoundIndex = parseInt(selectedMatchday.split(' ')[1]) - 1;
        const listItems = dropdownMenuRef.current.querySelectorAll('.list-group-item');
        if (listItems[currentRoundIndex]) {
          listItems[currentRoundIndex].scrollIntoView({
            block: 'center',
            behavior: 'auto'
          });
        }
      }, 0);
    }
  };

  const dropdownButton = dropdownButtonRef.current;
  if (dropdownButton) {
    dropdownButton.addEventListener('click', handleDropdownOpen);
    return () => dropdownButton.removeEventListener('click', handleDropdownOpen);
  }
}, [selectedMatchday]);

  return (
    <div>
    <div className='App-headerV8'>
    <Navbar />
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif'}}>SPFL {t('picks')}</h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
{/* Mobile Dropdown */}
<div className="dropdown d-inline-block d-md-none">
            <button
              className="btn btn-purple dropdown-toggle"
              type="button"
              id="groupStageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              ref={dropdownButtonRef}
            >
              {selectedMatchday}
            </button>
            <div
              className="dropdown-menu matchweek-dropdown"
              aria-labelledby="groupStageDropdown"
              ref={dropdownMenuRef}
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              <ul className="list-group">
                {dateRanges.map((range, index) => (
                  <li key={index} className="list-group-item">
                    <button
                      className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
                      onClick={() => handleMatchdaySelect(index)}
                      style={{ width: '100%' }}
                    >
                      {t('round')} {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>


  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '3rem' }} ref={containerRef}>
      <button className="btn btn-outline-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-outline-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            {t('round')} {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-outline-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>

 
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}
          <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin bg-transparent' style={{ paddingTop: '5px' }}>
  <div className='card ' style={{ backgroundColor: 'transparent', border: 'none' }}> {/* Makes the card background and border transparent */}
    <div className='card-body'>
      <h5 className='card-title'><ScottishStandingsPicks /></h5>
    </div>
  </div>
</div>

  
          {/* Main content area */}
          <div className='col-lg-7 col-12 bg-transparent ' style={{ backgroundColor: 'transparent', border: 'none', paddingTop: '5px' }}>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
  
              let points = 0;
  
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
  
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-1 card-hover bg-transparent border-light'>
                <div className='card-body text-center mb-1 border-light'>
                  <h5 className='card-title mb-3 text-light'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
              
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
                    <h6 className='card-title mb-3 text-light'>{stadiums[fixture.teams.home.name]}</h6>
                  )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center text-light">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5 className='text-light'>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
              
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'
          ? (inputsData[fixture.fixture.id]?.home ?? '')
          : (inputsData[fixture.fixture.id]?.home ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'home', value);
        } else {
          handleInputChange(fixture.fixture.id, 'home', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'}
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB'
      }}
    >
      {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? (
       <option value={inputsData[fixture.fixture.id]?.home}>
       {inputsData[fixture.fixture.id]?.home !== '' && inputsData[fixture.fixture.id]?.home !== undefined
         ? inputsData[fixture.fixture.id]?.home
         : window.innerWidth <= 768
         ? t('pick')
         : `No ${t('pick')}`}
     </option>
     
      ) : (
        <>
          <option value="">{t('pick')}</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>
<div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'
          ? (inputsData[fixture.fixture.id]?.away ?? '')
          : (inputsData[fixture.fixture.id]?.away ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'away', value);
        } else {
          handleInputChange(fixture.fixture.id, 'away', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'}
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB'
      }}
    >
      {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? (
        <option value={inputsData[fixture.fixture.id]?.away}>
          {inputsData[fixture.fixture.id]?.away !== '' && inputsData[fixture.fixture.id]?.away !== undefined
            ? inputsData[fixture.fixture.id]?.away
            : window.innerWidth <= 768
            ? t('pick')
         : `No ${t('pick')}`}
        </option>
      ) : (
        <>
          <option value="">{t('pick')}</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>


              
                    <div className="col text-center d-flex flex-column align-items-center text-light">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                  <div className='text-light'>{t('result')} {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
              
                  {/* Display the points scored for this fixture */}
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : 'text-light'}>
{t('you_scored')} <b>{points}</b> {points === 1 ? t('point') : t('points')} {t('for_this_game')}
</p>
                  )}
                </div>
              
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
                  style={{
                    backgroundColor: '#9370DB',
                    borderRadius: '5px',
                    borderBottomLeftRadius: '0px', // Set bottom-left corner radius to 0
                    borderBottomRightRadius: '0px' // Set bottom-right corner radius to 0
                  }}
                >
                  <p className="text-center mb-2 btn-sm text-bold text-light"  
                    style={{
                      color: '#fff',
                      paddingTop: '4px',
                      borderTopLeftRadius: '1px'
                    }}
                  >
                    {t('kick_off')} {convertToFormattedDateTime(fixture.fixture.date)}
                  </p>
                </div>
              </div>
              
              
              
              );
            })}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>{t('submit_picks')}</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p className='text-light'>{t('total_points')} <b>{totalPoints}</b></p>
            </div>
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
            <script type="text/javascript" src="//cdn.tsyndicate.com/sdk/v1/bi.js" data-ts-spot="c72b65b387304867b13f0012faf13d38" data-ts-width="900" data-ts-height="250" data-ts-extid="{extid}" async defer></script>  </div>
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                {t('your_picks_sent')}
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>{t('error_submitting_picks')}</p>}
          </div>

          <div className="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
  <AdComponent />
</div>
       
          {/* Standings Card for small screens */}
          <div className='col-12 d-lg-none mb-3' >
            <div className='card' style={{ backgroundColor: 'transparent', border: 'none' }}>
              <div className='card-body'>
                <h5 className='card-title'><ScottishStandingsPicks /></h5>
              </div>
            </div>
          </div>

  

        </div>
      </div>
    <Footer />
    </div>
  </div>
  

  );
}

export default ScottishPicks24;
