import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Adjust the path as necessary
import { useTranslation } from 'react-i18next';
import AdComponent from './AdComponent';
import TestApi from './Tournaments/testapi';

function News({ Email }) {
  const [brackets, setBrackets] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();


  useEffect(() => {
    const fetchBrackets = async () => {
      try {
        const database = getDatabase();
        const bracketsRef = ref(database, 'CreateArticle');
        onValue(bracketsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const bracketsArray = Object.entries(data);
            // Sort the bracketsArray by timestamp in descending order
            bracketsArray.sort((a, b) => new Date(b[1].timestamp) - new Date(a[1].timestamp));
            setBrackets(bracketsArray);
          }
        });
      } catch (error) {
        console.error('Error fetching brackets:', error);
      }
    };

    

    fetchBrackets();

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email); // Set user email when authenticated
      } else {
        setUserEmail(''); // Reset user email if not authenticated
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
  <div className='App-headerV8'>
    <Navbar />
    <div className="container bg-transparent">
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-10 col-lg-8 col-xl-7">
          <AdComponent />
          <h2 className="display-5 mb-3 text-center padtopinput text-light" style={{ fontFamily: 'Lato, sans-serif' }}>
            {t('predictify_news')}
          </h2>
          <hr className="w-50 mx-auto mb-3 mb-xl-9 border-light-subtle" />
        </div>
      </div>
    </div>

    {(userEmail === 'denis@kingjtech.com' || userEmail === 'thenumerousrandom@gmail.com' || userEmail === 'admin@predictify.app') && (
      <div className='container'>
        <Link to='/createarticle'>
          <p className='text-light text-center'>Create article</p>
        </Link>
      </div>
    )}
{/* Iframe */}
{/* <div className="d-block d-lg-none w-full">
      <div className="card bg-transparent border-0 text-light p-0 mb-4">
        <div className="ratio ratio-16x9">
          <iframe
            src="https://predictify.app"
            className="w-full d-block"
            style={{
              height: "80vh",
              border: "3px solid #ccc",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              width: "100vw",
              maxWidth: "100%",
              margin: "0 auto"
            }}
            title="Predictify"
          ></iframe>
        </div>
      </div>
    </div>
    <div className="container d-none d-lg-block">
      <div className="card bg-transparent border-0 text-light p-0 mb-4">
        <div className="ratio ratio-16x9">
          <iframe
            src="https://predictify.app"
            className="w-full d-block"
            style={{
              height: "80vh",
              border: "3px solid #ccc",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "100%",
              margin: "0 auto"
            }}
            title="Predictify"
          ></iframe>
        </div>
      </div>
    </div> */}
    {/* Iframe */}

    {brackets.map(([bracketId, bracket], index) => (
      <div key={index}>
        <section className="bsb-post-pagination-2 bg-transparent py-3 py-md-6 py-xl-9">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8">
                <div className="bg-transparent p-4 p-md-5 border border-light-subtle" style={{ borderRadius: '5px' }}>
                  <div className="row gy-3 align-items-md-center">
                    <div className="col-12 col-md-2 text-center text-md-start">
                      <Link to={`/article/${bracketId}`} className="bsb-hover-image d-block rounded overflow-hidden">
                        <img src={bracket.image} alt={bracket.title} className="img-fluid" style={{ width: '75px', height: '75px', objectFit: 'cover' }} />
                      </Link>
                    </div>
                    <div className="col-12 col-md-8 text-center text-md-end">
                      <Link to={`/article/${bracketId}`} className="d-block px-md-3 text-decoration-none">
                        <h5 className="link-light fw-bold fs-6 mb-3">{bracket.date}</h5>
                        <h5 className="link-light fw-bold fs-3 mb-3">{bracket.title}</h5>
                        <h2 className="link-light fs-5">{bracket.description}</h2>
                      </Link>
                    </div>
                    <div className="col-12 col-md-2 text-center text-md-end">
                      <Link to={`/article/${bracketId}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="" className="bi bi-arrow-right purple1 text-light" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ))}

    <Footer />
  </div>
</div>

  );
}

export default News;
