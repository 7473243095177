import { useState } from "react";
import Confetti from "react-confetti";

const TestApi = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f5f5f5", // Light background to enhance visibility
      }}
    >

      {/* Space dedicated for the iframe */}
      <div
        style={{
          width: "95vw", // Slight padding effect
          height: "1000vh", // Adjusted height for balance
          border: "3px solid #ccc", // Subtle border for visibility
          borderRadius: "10px", // Rounded corners for aesthetics
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for better visibility
          marginBottom: "20px", // Spacing below iframe
        }}
      >
        <iframe
          src="https://predictify.app"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          title="Predictify"
        ></iframe>
      </div>
    </div>
  );
};

export default TestApi;
